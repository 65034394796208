// TODO: move to form-- scope of controllers ?

import { Controller } from '@hotwired/stimulus'
import formChanges from 'shared/form_changes'

export default class extends Controller {
  static targets = ['savePrompt']
  static values = { skipCheck: { type: Boolean, default: false } }

  formSubmit() {
    this.skipCheckValue = true
  }

  isFormChanged() {
    const form_changes = formChanges(this.element)
    const changed = form_changes && form_changes.length > 0
    return changed
  }

  // callback for form_unsaved_changes:skip_check
  // disable page leave prompt
  receiveSkipCheck(event) {
    this.skipCheckValue = event.detail.value
  }

  // callback for modal-dismiss events
  // if user resigns from saving form we should re-enable back page leave prompt
  receiveModalDismiss() {
    //console.log('form-unsaved-changes | receiveModalDismiss')
    this.skipCheckValue = false
  }

  leavingPage(event) {
    // console.log(`form-unsaved-changes | this.skipCheckValue: ${this.skipCheckValue} | event:`, event)
    if(!this.skipCheckValue && this.isFormChanged()) {
      event.preventDefault()
      this.showSavePrompt()
      event.returnValue = ''
      return event.returnValue
    }
  }

  showSavePrompt() {
    if(this.hasSavePromptTarget) {
      this.savePromptTarget.classList.remove('hidden') // TODO: bs3, remove
      this.savePromptTarget.classList.remove('d-none')
      this.savePromptTarget.closest('form').scrollIntoView()
    }
  }
}
