// clickable elements like row

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  open(event) {
    const url = event.params.url
    if(event.params.modal == true) {
      this.renderTurboStreamFromUrl(url)
    } else {
      document.location = url
    }
  }

  renderTurboStreamFromUrl(url) {
    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
    .then(r => r.text())
    .then(html => {
      html = `<turbo-stream action="replace" target="modal"><template>${html}</template></turbo-stream>`
      //console.log(html);
      Turbo.renderStreamMessage(html)
    })
  }
}
