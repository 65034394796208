import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['atcWarning', 'frameTable', 'toggle']
  static values = { core: Boolean, apa: Boolean, atc: Boolean, baseUrl: String }

  connect() {
    const that = this
    _.each(['core', 'apa', 'atc'], (k) => {
      that._getToggleTarget(k).checked = !!that[`${k}Value`]
    })
  }

  toggle(event) {
    const activity_type = event.params.activityType
    const checked = event.target.checked

    this[`${activity_type}Value`] = checked

    const new_url = `${this.baseUrlValue}?core=${this.coreValue}&apa=${this.apaValue}&atc=${this.atcValue}`

    this.frameTableTarget.src = new_url
  }

  atcValueChanged(value, _prevValue) {
    if(this.hasAtcWarningTarget) {
      if(value == true)  {
        this.atcWarningTarget.classList.remove('hidden') // bs3, TODO: remove
        this.atcWarningTarget.classList.remove('d-none')
      } else {
        this.atcWarningTarget.classList.add('hidden') // bs3, TODO: remove
        this.atcWarningTarget.classList.add('d-none')
      }
    }
  }

  _getToggleTarget(value) {
    return this.toggleTargets.find(t => t.value == value)
  }
}
