import { Controller } from '@hotwired/stimulus'

// TODO: remove bs3 part when switched to bs5
export default class extends Controller {
  static values = { bs5: { type: Boolean, default: false } }

  connect() {
    const meta = document.querySelector('meta[name="bs5"]')
    this.bs5Value = meta && meta.getAttribute('content') == 'true'

    if(this.bs5Value) {
      if(localStorage['timetable_tab']) {
        history.replaceState(null, null, localStorage['timetable_tab'])
      }

      const hash = location.hash ? location.hash : '#tab-all-activities'
      const href = hash.replace('tab-', '')

      let el = this.element.querySelector(`a[data-bs-toggle="tab"][href = '${href}']`)
      if(!el) {
        el = this.element.querySelector('[data-bs-toggle="tab"]') // grab first tab
      }

      const tab = new bootstrap.Tab(el)
      tab.show()

      this.element.addEventListener('show.bs.tab', (event) => {
        const hash = event.target.hash
        location.hash = hash.replace('#', '#tab-')

        // const tab = new bootstrap.Tab(event.target)
        // tab.show()

        localStorage['timetable_tab'] = location.hash
      })
    } else { // bs3, TODO: remove
      // const hash = window.location.hash
      const hash = localStorage['timetable_tab']

      if(hash) {
        $(`#timetable-tabs ul[role=tablist] a[href='${hash}']`).tab('show')
      } else {
        $("#timetable-tabs ul[role=tablist] a[href='#all-activities']").tab('show')
      }

      $('#timetable-tabs ul[role=tablist] a').on('click', function (e) {
        let scrollmem = $('body').scrollTop()
        // window.location.hash = this.hash
        localStorage['timetable_tab'] = this.hash
        $('html, body').scrollTop(scrollmem)
      })
    }
  }
}
