import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'repeatsMonthlyOn', 'repeatsMonthlyEach',
                     'eachDayHiddenFieldsContainer', 'eachDayHiddenFieldTemplate' ]

  // Schedule/Every n month(s) radios
  toggleWhen(event) {
    //console.log('job-content--repeats-monthly | toggleWhen()', event.params)
    const when = event.params.when

    if(when == 'on') {
      this.repeatsMonthlyOnTarget.classList.remove('hidden') // TODO: remove bs3
      this.repeatsMonthlyOnTarget.classList.remove('d-none')
      this.repeatsMonthlyEachTarget.classList.add('hidden')
      this.repeatsMonthlyEachTarget.classList.add('d-none')
    } else if (when == 'each') {
      this.repeatsMonthlyOnTarget.classList.add('hidden')
      this.repeatsMonthlyOnTarget.classList.add('d-none')
      this.repeatsMonthlyEachTarget.classList.remove('hidden')
      this.repeatsMonthlyEachTarget.classList.remove('d-none')
    }
  }

  // Schedule/Repeats Monthly/Each click day circle
  toggleDay(event) {
    //console.log('job-content--repeats-monthly | toggleDay | event.params | this.element', event.params, this.element)
    const day = event.params.day
    const checked = event.target.classList.contains('selected')

    //console.log('this.eachDayHiddenFieldTemplateTarget', this.eachDayHiddenFieldTemplateTarget)

    event.target.classList.toggle('selected')

    let dayHiddenField = this.eachDayHiddenFieldsContainerTarget.querySelector(`input[value='${day}']`)

    //console.log('dayHiddenField', dayHiddenField)

    if(checked) {
      this.eachDayHiddenFieldsContainerTarget.removeChild(dayHiddenField)
    } else {
      const newDayHiddenField = this.eachDayHiddenFieldTemplateTarget.innerHTML.replace(/DAY/g, day)
      // console.log('newDayHiddenField', newDayHiddenField)
      this.eachDayHiddenFieldsContainerTarget.insertAdjacentHTML('beforeend', newDayHiddenField)
    }
  }
}
