// show target when checkbox/radio checked
// hideOnChecked (Boolean): behave reverse way so hide on check checkbox/radio

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  // TODO try to make it more flexible with onfly targets definition https://gist.github.com/mrmartineau/a4b7dfc22dc8312f521b42bb3c9a7c1e#empty-class
  static targets = ['switch', 'switchYes', 'switchNo']
  static values = { hideOnChecked: Boolean }

  switch(event) {
    // console.log('switch | event.params', event.params)

    if(this.hasSwitchYesTarget && this.hasSwitchNoTarget) {
      this.switchDouble(event)
    } else {
      if(this.switchTargets.length > 1) {
      } else {
        this.switchSingle(event)
      }
    }
  }

  // TODO: remove hidden when whole app switched to boostrap 5

  switchDouble(event) {
    if(this.isChecked(event)) {
      this.switchYesTarget.classList.remove('hidden')
      this.switchYesTarget.classList.remove('d-none')
      this.switchNoTarget.classList.add('hidden')
      this.switchNoTarget.classList.add('d-none')
    } else {
      this.switchYesTarget.classList.add('hidden')
      this.switchYesTarget.classList.add('d-none')
      this.switchNoTarget.classList.remove('hidden')
      this.switchNoTarget.classList.remove('d-none')
    }
  }

  switchSingle(event) {
    if(this.isChecked(event)) {
      this.switchTarget.classList.remove('hidden')
      this.switchTarget.classList.remove('d-none')
    } else {
      this.switchTarget.classList.add('hidden')
      this.switchTarget.classList.add('d-none')
    }
  }

  isChecked(event) {
    let is_checked = false

    if(event.target.type == 'radio') {
      is_checked = event.target.value == 'true' || event.target.value == 'yes'
    } else if (event.target.type == 'checkbox') {
      is_checked = event.target.checked
    }

    if(this.hideOnCheckedValue === true) {
      is_checked = !is_checked
    }
    return is_checked
  }
}
