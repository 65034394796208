import { Controller } from '@hotwired/stimulus'
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

export default class extends Controller {

  connect() {
    $.ajax({
      context: this,
      type: 'GET',
      contentType: 'application/json',
      dataType: 'json',
      success: function(data) {
        const start_date = this.element.dataset.jobPlanStartDate
        const end_date = this.element.dataset.jobPlanEndDate

        const calendar = new Calendar(this.element, {
          timeZone: 'UTC',
          plugins: [ dayGridPlugin, interactionPlugin ],
          events: data,
          firstDay: 1,
          header: {
            left: '',
            center: 'title',
            right: 'prev,next'
          },
          buttonIcons: {
            prev: 'left-single-arrow',
            next: 'right-single-arrow'
          },
          defaultDate: start_date,
          eventTimeFormat: {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          },
          eventTextColor: 'black',
          validRange: {
            start: start_date,
            end: end_date
          },
          eventClick: function(info) {
            const job_plan_id = info.event.extendedProps.job_plan_id
            const job_content_id = info.event.extendedProps.job_content_id
            $('#activity-modal .modal-content').load(`/non_medical/online_job_plans/${job_plan_id}/activities/${job_content_id}/edit`)
            $('#activity-modal').modal('show')
          }
        })
        calendar.render()
      },
      error: function(err) {
        this.element.append(err)
      }
    })
  }

}
