import { Controller } from '@hotwired/stimulus'

// TODO: when bs5 done remove hidden
export default class extends Controller {
  static targets = ['itemsList', 'noItemsNotice']

  toggle_edit(event) {
    event.preventDefault()
    let container = event.target.closest('.nested-fields')
    container.querySelector('.work-item-show').classList.add('hidden')
    container.querySelector('.work-item-show').classList.add('d-none')
    container.querySelector('.work-item-edit').classList.remove('hidden')
    container.querySelector('.work-item-edit').classList.remove('d-none')
  }

  toggle_show(event) {
    event.preventDefault()
    let container = event.target.closest('.nested-fields')
    container.querySelector('.work-item-show').classList.remove('hidden')
    container.querySelector('.work-item-show').classList.remove('d-none')
    container.querySelector('.work-item-edit').classList.add('hidden')
    container.querySelector('.work-item-edit').classList.add('d-none')
  }

  // triggered when new work item is added or removed
  changeItemsCount() {
    let items_count = this.itemsListTarget.querySelectorAll('.nested-fields:not(.hidden):not(.d-none)').length

    // show or hide notice
    if(items_count > 0) {
      this.noItemsNoticeTarget.classList.add('hidden')
      this.noItemsNoticeTarget.classList.add('d-none')
    } else {
      this.noItemsNoticeTarget.classList.remove('hidden')
      this.noItemsNoticeTarget.classList.remove('d-none')
    }
  }
}
