// copied from non-medical part and changed a bit

import { Controller } from '@hotwired/stimulus'

import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

export default class extends Controller {
  static values = { url: String, itemUrlTemplate: String }

  connect() {
    const that = this

    $.ajax({
      url: that.urlValue,
      context: this,
      type: 'GET',
      contentType: 'application/json',
      dataType: 'json',
      success: function(data) {
        const start_date = this.element.dataset.jobPlanStartDate
        const end_date = this.element.dataset.jobPlanEndDate

        data = _.map(data, (item) => {
          item.url = that._prepareItemUrl(item.job_content_id)
          return item
        })

        const calendar = new Calendar(this.element, {
          timeZone: 'UTC',
          plugins: [ dayGridPlugin, interactionPlugin ],
          events: data,
          firstDay: 1,
          header: {
            left: '',
            center: 'title',
            right: 'prev,next'
          },
          buttonIcons: {
            prev: 'left-single-arrow',
            next: 'right-single-arrow'
          },
          defaultDate: start_date,
          eventTimeFormat: {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          },
          eventTextColor: 'black',
          validRange: {
            start: start_date,
            end: end_date
          },
          eventRender: function (event) {
            event.el.setAttribute('data-turbo', 'true')
            event.el.setAttribute('data-turbo-frame', 'modal')
          }
        })
        calendar.render()
      },
      error: function(err) {
        this.element.append(err)
      }
    })
  }

  _prepareItemUrl(item_id) {
    return this.itemUrlTemplateValue.replace(/ID/g, item_id)
  }
}
