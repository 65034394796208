// TODO: remove, bs3

// used in app/views/online_appraisals/submit_to_appraiser (modal)

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'formContent', 'formSubmit', 'formCancel' ]
  static values = { modalSelector: String, appraisalUrl: String }

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail

    this.formContentTarget.innerHTML = data.success_html
    this.formSubmitTarget.remove() // remove Continue submit button

    // change Cancel button class and change text Continue
    this.formCancelTarget.classList.remove('btn-danger')
    this.formCancelTarget.classList.add('btn-success')
    this.formCancelTarget.innerHTML = 'Continue'

    const modalEl = event.target.closest('.modal')

    modalEl.setAttribute('data-reload', 'true')
    modalEl.setAttribute('data-referrer', this.appraisalUrlValue)
  }

  onPostError(event) {
    let [data, status, xhr] = event.detail

    this.formContentTarget.innerHTML = data.error_html
    this.formSubmitTarget.remove() // remove Continue submit button
  }
}
