// receive event from justification controller and change menu item and header rag icon and color respectively

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['nav', 'header']

  justificationUpdated({ detail: content }) {
    const justification = content.justification
    const newRagClass = `rag-${justification.rag_status}`

    // update nav item rag icon
    let ragBox= this.navTarget.querySelector(`[data-id='${justification.appraisal_dataset_id}']`)
    _.map(ragBox.classList, function(k) {
      if(k && k.startsWith('rag-')) {
        ragBox.classList.remove(k)
      }
    })
    ragBox.classList.add(newRagClass)

    // update header rag color
    let header = this.headerTarget
    _.map(header.classList, function(k) {
      if(k && k.startsWith('rag-')) {
        header.classList.remove(k)
      }
    })
    this.headerTarget.classList.add(newRagClass)
  }
}
