// repeats_every_n_weeks (select), repeats_weekly_each_days_of_the_week (day boxes),
// repeats_rota (week circles) management

// TODO refactor more by using targets
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['everyWeeks', 'weeksOn', 'repeatsRota', 'onWeeks']

  connect(){
    // get value of repeats_every_n_weeks select 'Every' (1..52) 'week(s)' and use to show relevant week circles
    const everyWeeks = this.everyWeeksTarget.value

    this.pluralizeWeek(everyWeeks)

    if (everyWeeks > 1) {
      this.hideAllWeekCircles()
      this.repeatsRotaTarget.classList.remove('d-none')
      this.showWeekCircles(everyWeeks)
    }

    // get value of hidden repeats_rota select [(1..52)] and use to select week circles
    let repeats_rota = $("#job_content_repeats_rota").val()
    this.selectWeekCircles(repeats_rota)

    // get value of 'repeats_weekly_each_days_of_the_week' select (Mon-Sun) and use to highlight selected days
    let days_of_the_week = $("#job_content_repeats_weekly_each_days_of_the_week").val()
    this.selectDaysOfTheWeek(days_of_the_week)
  }

  // when select an option from the on 'Every' (1..52) 'week(s)' select
  onSelectEveryWeeksOption(event) {
    const everyWeeks = this.everyWeeksTarget.value

    // deselect all 'On Weeks' outside 'Every n weeks on' Range
    const onWeeks = [...this.onWeeksTarget.options].filter(option => option.selected).map(option => option.value)

    if(everyWeeks && onWeeks.length > 0 && _.last(onWeeks) > everyWeeks) {
      const selected_on_weeks = _.filter(onWeeks, (w) => { return(parseInt(w) <= parseInt(everyWeeks)) })

      $(this.onWeeksTarget).val(selected_on_weeks)

      const deselect_circles = _.difference(onWeeks, selected_on_weeks)
      _.each(deselect_circles, (v) => {
        $(`.rota-week-selector td[data-value='${v}']`).removeClass('selected')
      })
    }

    this.pluralizeWeek(everyWeeks)

    if (everyWeeks > 1) {
      this.repeatsRotaTarget.classList.remove('d-none')
    } else {
      this.repeatsRotaTarget.classList.add('d-none')
    }

    this.hideAllWeekCircles()
    this.showWeekCircles(everyWeeks)
  }

  // when click on week circle
  onClickWeekCircle(event) {
    //console.log('job-content--weekly-repeats | onClickWeekCircle()')
    // add selected class
    $(event.target).toggleClass('selected')

    // and update hidden array field
    let selected_weeks = []
    $.each($(event.target).closest('tr').children("td.selected"), function( index, value ) {
      selected_weeks.push(value.getAttribute('data-value'))
    })
    $("#job_content_repeats_rota").val(selected_weeks)
  }

  // when click on day of the week
  onClickDay(event) {
    //console.log('job-content--weekly-repeats | onClickDay()', event)
    // add selected class
    const td_el = event.target.closest('td')
    td_el.classList.toggle('selected')

    // and update hidden array field
    const selected_days = Array.from(td_el.closest('tr').querySelectorAll('td.selected')).map(td => td.getAttribute('data-value'))
    $("#job_content_repeats_weekly_each_days_of_the_week").val(selected_days)
  }

  hideAllWeekCircles() {
    $('.rota-week-selector .clickable_td').hide()
  }

  showWeekCircles(everyWeeks) {
    // show clickable_td for week options if repeating less than once a week
    if (everyWeeks > 1){
      this.hideAllWeekCircles()
      this.repeatsRotaTarget.classList.remove('d-none') // bs5
      this.repeatsRotaTarget.classList.remove('hidden') // TODO: remove bs3
      everyWeeks = parseInt(everyWeeks, 10)
      ++everyWeeks
      var range = _.range(1,everyWeeks, 1)
      $.each(range, function( everyWeeks, value ) {
        $("td[data-value='" + value + "']").show()
      });
    }
  }

  selectWeekCircles(repeats_rota) {
    if (repeats_rota) {
      $.each(repeats_rota, function( index, value ) {
        $("td[data-value='" + value +"']").addClass('selected')
      })
    }
  }

  selectDaysOfTheWeek(days_of_the_week) {
    if (days_of_the_week) {
      $.each(days_of_the_week, function( index, value ) {
        $("td[data-value='" + value +"']").addClass('selected')
      })
    }
  }

  pluralizeWeek(count){
    const new_text = count > 1 ? 'weeks' : 'week'
    this.weeksOnTarget.textContent = new_text
  }
}
