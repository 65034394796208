// used to render overlappings shifts/shift swaps/leave periods in leave period modal
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { calendarBaseUrl: String,
                    userEmail: String,
                    authToken: String,
                    loading: { type: Boolean, default: false },
                    approve: { type: Boolean, default: false } } // indicates that approver opened the modal

  static targets = [ 'frame', 'loader', 'fromDate', 'untilDate', 'startTime', 'endTime' ]

  connect() {
    const modalEl = this.element.closest('.modal')

    // TODO: pass stimulus way when eleave does not use backbone
    const tenant_has_erostering_integration = modalEl.getAttribute('data-tenant-has-erostering-integration')
    if(tenant_has_erostering_integration == 'false') {
      this.frameTarget.closest('.panel').classList.add('hidden')
      return
    }

    this.userEmailValue = modalEl.getAttribute('data-roster-overlaps-user-email')
    this.calendarBaseUrlValue = modalEl.getAttribute('data-roster-overlaps-calendar-url')
    this.authTokenValue = modalEl.getAttribute('data-roster-overlaps-auth-token')
    this.approveValue = modalEl.getAttribute('data-roster-overlaps-approve')

    addEventListener('turbo:before-fetch-request', async (event) => {
      if(event.target.getAttribute('id') == this.frameId()) {
        this.loadingValue = true
      }
    })

    addEventListener("turbo:frame-render", async (event) => {
      if(event.target.getAttribute('id') == this.frameId()) {
        this.loadingValue = false
      }
    })

    // hide turbo frame with mini calendar if date changed
    this.element.addEventListener('eleave:leave_period:changed', (_e) => {
      this.frameTarget.classList.add('hidden')
    })

    // preload overlaps calendar if approver opened eleave form
    if(this.approveValue) {
      this.frameTarget.src = this.prepareUrl()
      $('#roster-overlaps').collapse('show')
      // TODO: when bs5 used
      // new bootstrap.Collapse('#roster-overlaps', 'show')
    }
  }

  loadingValueChanged() {
    if(this.loadingValue) {
      this.loaderTarget.classList.remove('hidden')
      this.frameTarget.classList.add('hidden')
    } else {
      this.loaderTarget.classList.add('hidden')
      this.frameTarget.classList.remove('hidden')
    }
  }

  frameId() {
    return this.frameTarget.getAttribute('id')
  }

  checkRoster(event) {
    event.preventDefault()

    const url = this.prepareUrl()
    this.frameTarget.src = url // it causes to reload iframe using external eRostering url with datetime range passed in query params
  }

  prepareUrl() {
    const starts_at_str = `${this.fromDateTarget.getAttribute('data-value')} ${this.startTimeTarget.getAttribute('data-value')}`
    const ends_at_str = `${this.untilDateTarget.getAttribute('data-value')} ${this.endTimeTarget.getAttribute('data-value')}`
    const starts_at_iso = new Date(starts_at_str).toISOString()
    const ends_at_iso = new Date(ends_at_str).toISOString()

    let url = new URL(this.calendarBaseUrlValue)
    url.searchParams.set('turbo_frame_id', this.frameTarget.getAttribute('id'))
    url.searchParams.set('starts_at', starts_at_iso)
    url.searchParams.set('ends_at', ends_at_iso)
    url.searchParams.set('email', this.userEmailValue)
    url.searchParams.set('auth_token', this.authTokenValue)

    return url.toString()
  }
}
