import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { confirmEventName: { type: String, default: 'modal:confirm' } }

  connect() {
    //console.log('modal | connect', this.element)

    const meta = document.querySelector('meta[name="bs5"]')
    const bs5 = meta && meta.getAttribute('content') == 'true'

    // TODO: remove bs3 part and condition when bs5 used for whole app
    if(bs5) {
      //console.log('modal | bs5')
      this.modal = new bootstrap.Modal(this.element, {})
      this.modal.show()

      this.element.addEventListener('hidden.bs.modal', function (event) {
        const dataset = event.target.dataset
        //console.log('modal bs5 | hidden.bs.modal | dataset', dataset)

        if (dataset.reload) {
          if(dataset.referrer) {
            window.location.href = dataset.referrer
          } else {
            window.location.reload(true)
          }
        }
      })

    } else { // bs3
      $('.modal-backdrop').remove() // to prevent show multiple times, refactor?

      const modalEl = $(this.element)
      modalEl.modal()

      modalEl.on('hide.bs.modal', (event) => {
        const dataset = event.target.dataset

        //console.log('modal | on hide.bs.modal | dataset', dataset)

        if (dataset.reload) {
          if(dataset.referrer) {
            window.location.href = dataset.referrer
          } else {
            window.location.reload(true)
          }
        }
      })
    }
  }

  closeCallback() {
    this.closeModal()
  }

  closeModal() {
    const meta = document.querySelector('meta[name="bs5"]')
    const bs5 = meta && meta.getAttribute('content') == 'true'

    // TODO: remove bs3 part and condition when bs5 used for whole app
    if(bs5) {
      const modal = bootstrap.Modal.getInstance(this.element)
      modal.hide()
    } else { // bs3
      const id = this.element.getAttribute('id')

      $(`#${id}`).hide()
      $('.modal-backdrop').remove()
    }
  }

  // on click Cancel or Close
  dismiss() {
    this._dispatchDismiss()
  }

  dispatchConfirm(event) {
    //console.log('modal | dispatchConfirm | event.params', event.params)

    const event_detail = { confirm_button: event.params.confirmButton,
                           source_action_name: event.params.sourceActionName,
                           source_url: event.params.sourceUrl }

    const event_name = event.params.confirmEventName ? event.params.confirmEventName : this.confirmEventNameValue

    //console.log('modal | dispatchConfirm | event_detail | event_name', event_detail, event_name)

    const custom_event = new CustomEvent(event_name, { detail: event_detail })
    window.dispatchEvent(custom_event)
  }

  _dispatchDismiss() {
    const custom_event = new CustomEvent('modal:dismiss')
    window.dispatchEvent(custom_event)
  }
}
