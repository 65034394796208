// responsible for anything happens when job content modal form is submitted
// Caution! Used in both medical and non-medical job content forms

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'formContainer', 'flashContainer' ]

  submitEnd(event) {
    if(event.detail.success) {
      const modalEl = $(this.getModal(event))
      const data_context = event.detail.formSubmission.submitter.dataset.context
      const close = ['create', 'save'].includes(data_context)

      // if user used one of recalculate buttons also reload page after manual modal close
      if(close || data_context == 'recalculate') {
        modalEl.attr('data-reload', true)
      }

      // auto close modal if user clicked Save or Create and no errors
      // TODO: delegate to modal controller, reload too
      if(close) {
        modalEl.modal('hide')
      }
    }
  }

  getModal(e) {
    return e.target.closest('.modal')
  }
}
