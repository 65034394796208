import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['add_item', 'template', 'removeItem']
  static values = { key: String,
    allowRemoveLastItem: { type: Boolean, default: true },
    bs5: { type: Boolean, default: false } } // TODO: remove bs5 value when all rewritten

  connect() {
    const meta = document.querySelector('meta[name="bs5"]')
    this.bs5Value = meta && meta.getAttribute('content') == 'true'
  }

  add_association(event) {
    event.preventDefault()
    const content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.add_itemTarget.insertAdjacentHTML('afterbegin', content)
  }

  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest('.nested-fields')
    item.querySelector("input[name*='_destroy']").value = 'true'
    //const attachmentIdsInput = item.querySelector("input[name*='attachment_id']")
    //attachmentIdsInput.remove()

    if(this.bs5Value) {
      item.classList.add('d-none')
    } else {
      item.classList.add('hidden')
    }

    this.updateRemoveItemVisibility()
  }

  removeItemTargetConnected() {
    this.updateRemoveItemVisibility()
  }

  updateRemoveItemVisibility() {
    if(this.allowRemoveLastItemValue) {
      return
    }

    const visibleNestedFields = this.element.querySelectorAll('.nested-fields:not(.d-none)')
    const length = visibleNestedFields.length

    if(length == 1) {
      this.removeItemTargets.forEach(target => target.classList.add('d-none'))
    } else {
      this.removeItemTargets.forEach(target => target.classList.remove('d-none'))
    }
  }
}
