// disable job plan form when complete
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    $('textarea').autosize({ append: "\n\n" });  // in non-backbone version it uses textarea-autogrow controller

    if(this.data.get("disable") == 'true'){
      this.disableForm()
    }
  }

  disableForm() {
    // Disable form inputs (not comments - can add those to complete job plans)

    $("#section-view input").attr('disabled', true)
    // Save
    $('#section-view [type="submit"]').attr('disabled', true)
    // Cancel
    $("#section-view .btn-cancel").attr('disabled', true)

    $('select').trigger("chosen:updated")
  }
}
