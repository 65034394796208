// used for forms in modal for new/existing edoc

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['searchQuery', 'searchResult', 'templateTitleRow',
                    'titleSingleContainer', 'titleRowsContainer', //'ownerContainer',
                    'attachNewSubmit', 'attachExistingSubmit', 'checkboxExisting']

  static values = { filesCount: { type: Number, default: 0 } }

  attachSubmitEnd(event) {
    if(event.detail.success) {
      this.dispatchModalClose()
      this.dispatch('modal-dismiss')
    }
  }

  createUpdateSubmitEnd(event) {
    if(event.detail.success) {
      this.dispatchModalClose()
    }
  }

  dispatchModalClose() {
    this.dispatch('modal-close')
  }

  attachExistingSearch(_event) {
    const query = this.searchQueryTarget.value.toUpperCase()
    const label_items = this.searchResultTarget.querySelectorAll("label[for^='edoc_']")

    // Loop through all label items, and hide those which do not match the search query
    for (const item of label_items) {
      if (item['innerText'].toUpperCase().indexOf(query) > -1) {
        item.classList.remove('hidden') // TODO: remove when all attach eDoc modals use bs5
        item.classList.remove('d-none')
      } else {
        item.classList.add('hidden') // TODO: remove when all attach eDoc modals use bs5
        item.classList.add('d-none')
      }
      const inputId = item.getAttribute('for')
      const input = this.searchResultTarget.querySelector(`#${inputId}`)
      input.checked = false // deselect any radio/checkbox to prevent submitting hidden value
    }

    this.filesCountValue = 0
  }

  fileUploadMultipleSelectedCallback(event) {
    const fileNames = event.detail.fileNames
    this.titleRowsContainerTarget.innerHTML = null

    let index = 0
    for(const fileName of fileNames) {
      let content = this.templateTitleRowTarget.innerHTML.
        replace(/EDOC_INDEX/g, index).
        replace(/EDOC_TITLE/g, this.prepareFileTitle(fileName))

      content = content.replace(/EDOC_LABEL_CSS_CLASS/g, index > 0 ? 'hidden' : '') // TODO: remove hidden all attach eDoc modals use bs5
      content = content.replace(/EDOC_LABEL_CSS_CLASS/g, index > 0 ? 'd-none' : '') // TODO: remove hidden all attach eDoc modals use bs5

      this.titleRowsContainerTarget.insertAdjacentHTML('beforeEnd', content)

      this.titleRowsContainerTarget.classList.remove('hidden') // TODO: remove hidden all attach eDoc modals use bs5
      this.titleRowsContainerTarget.classList.remove('d-none')

      index++
    }

    this.filesCountValue = fileNames.length
  }

  fileUploadSingleSelectedCallback(event) {
    const fileName = event.detail.fileNames[0]
    const edocTitle = this.prepareFileTitle(fileName)

    const textInput = this.titleSingleContainerTarget.querySelector('input[type=text]')
    textInput.setAttribute('placeholder', edocTitle)
    textInput.setAttribute('value', '')
    this.titleSingleContainerTarget.classList.remove('hidden') // TODO: remove hidden all attach eDoc modals use bs5
    this.titleSingleContainerTarget.classList.remove('d-none')
  }

  changeCheckboxExisting(event) {
    this.filesCountValue = this.checkboxExistingTargets.filter(t => t.checked).length
  }

  filesCountValueChanged(count, was) {
    let target = null
    if(this.hasAttachExistingSubmitTarget) {
      target = this.attachExistingSubmitTarget
    } else if (this.hasAttachNewSubmitTarget) {
      target = this.attachNewSubmitTarget
    }

    if(!target || was === undefined) {
      return
    }

    const attr_name = count > 1 ? 'data-submit-plural' : 'data-submit-singular'
    const new_value = target.getAttribute(attr_name)
    target.value = new_value
  }

  fileUploadMultipleResetCallback(event) {
    this.titleRowsContainerTarget.innerHTML = null
    this.titleRowsContainerTarget.classList.add('hidden') // TODO: remove hidden all attach eDoc modals use bs5
    this.titleRowsContainerTarget.classList.add('d-none')
    this.filesCountValue = 0
  }

  fileUploadSingleResetCallback(event) {
    const textInput = this.titleSingleContainerTarget.querySelector('input[type=text]')
    textInput.setAttribute('value', '')
    this.titleSingleContainerTarget.classList.add('hidden') // TODO: remove hidden all attach eDoc modals use bs5
    this.titleSingleContainerTarget.classList.add('d-none')
  }

  removeTitleContainer(event) {
    event.preventDefault()
    this.filesCountValue--

    const index = event.params.edocIndex
    this.titleRowsContainerTarget.querySelector(`[data-edoc-index='${index}']`).remove()

    // we can not just use this.dispatch here because we communicate with child controller not parent
    // but we can use window dispatchEvent, no need to use this.childrenTarget.dispatchEvent because child is unique in that case
    const custom_event = new CustomEvent('file-removed', { detail: { new_edocs_count: this.filesCountValue } })
    window.dispatchEvent(custom_event)
  }

  prepareFileTitle(filename) {
    return filename.split('.')[0].toLowerCase().replace(/-/g, ' ').replace(/_/g, ' ').replace(/\b\w/g, s => s.toUpperCase())
  }
}
